
import { defineComponent, onMounted } from 'vue';

const Home = defineComponent({
  name: 'Home',

  setup() {
    const onRefresh = () => {
      window.location.reload();
    };

    const interval = () => {
      setInterval(() => onRefresh(), 60000);
    };

    const metaTitle = () => {
      document.title = 'eLuma - Maintenance';
    };

    onMounted(() => {
      metaTitle();
      interval();
    });

    return { onRefresh };
  },
});

export default Home;
